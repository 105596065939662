// ModalManager.js
import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { AnimatePresence, m } from 'framer-motion'; // Import AnimatePresence
import BulkImportErrorModal from 'components/modals/BulkImportErrorModal';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import AdvancedFiltersModal from 'components/modals/AdvancedFiltersModal';
import PreviewCsvData from 'views/admin/create-deal/components/PreviewCsvData';
import { modalContainerVariant } from 'animations';
import LinkEditModal from 'views/admin/edit-deal/components/LinkEditModal';
import CrawlerModal from 'views/admin/links/components/CrawlerModal';
import EditModal from 'views/admin/countries/components/EditModal';
import EditWebsite from 'views/admin/websites/components/EditWebsite';
import EditUser from 'views/admin/users/components/EditUser';
import EditSupplier from 'views/admin/suppliers/components/EditSupplier';
import EditUnit from 'views/admin/units/components/EditUnit';
import CreateCountry from 'views/admin/countries/components/CreateCountry';

const ModalManager = ({}) => {
  const { name, props } = useStoreState((state) => state.modal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const MODAL_COMPONENTS = {
    'bulk-import-error-modal': BulkImportErrorModal,
    confirmation: ConfirmationModal,
    'advanced-filters': AdvancedFiltersModal,
    'preview-csv': PreviewCsvData,
    'edit-link': LinkEditModal,
    'view-crawls': CrawlerModal,
    'create-country': CreateCountry,
    'edit-country': EditModal,
    'edit-website': EditWebsite,
    'edit-user': EditUser,
    'edit-supplier': EditSupplier,
    'edit-unit': EditUnit,
  };

  const ModalComponent = MODAL_COMPONENTS[name];

  return (
    <AnimatePresence>
      {name && (
        <>
          <m.div
            className='modal-backdrop fixed inset-0 z-[1000] flex h-full w-full items-center justify-center bg-gray-900/50 backdrop-blur-md backdrop-brightness-50'
            key='modal'
            initial='hidden'
            animate='visible'
            exit='hidden'
            variants={modalContainerVariant}
            transition={{ duration: 0.3 }}
            onClick={() => {
              console.log('close modal');
              closeModal();
            }}
          ></m.div>
          <ModalComponent {...props} onClose={closeModal} />
        </>
      )}
    </AnimatePresence>
  );
};

export default ModalManager;
